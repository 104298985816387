import { faCartShopping, faChevronLeft, faEdit, faImage, faLocationDot, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, ToastContainer, Toast } from 'react-bootstrap';
import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import constants from '../constants';
import { createCanvas } from 'canvas';
import QuantityInput from '../component/QuantityInput';

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselIndex: 0,
            product: props.product,
            productQty: [],
            toast: {},
            currentlyEditing: "",
            newOptions: []
        };
    };

    getOptionImage(option) {
        if (option.media) {
            return <img src={`${constants.PRODUCT_MEDIA_BASE_URL}/${option.media.filename}`} className="ms-auto" width="100px" height="100px" />
        } else if (option.image) {
            return <img src={URL.createObjectURL(option.image)} className="ms-auto" width="100px" height="100px" />
        } else {
            return <div className="bg-light d-flex flex-column justify-content-center align-items-center ms-auto" style={{width:'100px',height:'100px'}}><FontAwesomeIcon icon={faImage} className="fs-3 mb-1" /><span className="text-center">Upload Image</span></div>
        };
    };

    showOptions() {
        const examples = ["Original", "Chocolate", "Cream", "Peanut Butter", "Matcha"];
        return this.state.newOptions.map((item, idx) => {
            return (
                <li className="list-group-item d-flex flex-column" key={idx}>
                    <div className={`text-danger ms-2 ms-auto ${idx===0?'d-none':''}`} onClick={() => {
                        const confirmDelete = window.confirm("Tap 'OK' to delete option");
                        if (confirmDelete) {
                            const temp = this.state.newOptions;
                            temp.splice(idx, 1);
                            this.setState({newOptions:temp});
                        }
                    }}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span className="ms-2">Remove Option</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <div className="form-group">
                                <label htmlFor={`optionName_${idx}`} className="fw-bold">Name</label>
                                <input type="text" className="form-control me-3" id={`optionName_${idx}`} name={`optionName_${idx}`} minLength={1} maxLength={20} placeholder={`e.g. ${examples[idx]}`} value={this.state.newOptions.length===1?'Default':item.name} onChange={e => {
                                    var temp = this.state.newOptions;
                                    temp[idx].name = e.target.value;
                                    this.setState({newOptions:temp});
                                }} disabled={this.state.newOptions.length===1} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`optionCostPrice_${idx}`} className="fw-bold">Cost Price</label>
                                <input type="number" className="form-control me-3" name={`optionCostPrice_${idx}`} min={0.01} max={999.99} step={0.01} placeholder="e.g. 4.40" value={item.costPrice} onChange={e => {
                                    var temp = this.state.newOptions;
                                    temp[idx].costPrice = e.target.value;
                                    this.setState({newOptions:temp});
                                }} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`optionListPrice_${idx}`} className="fw-bold">List Price</label>
                                <input type="number" className="form-control me-3" name={`optionListPrice_${idx}`} min={0.01} max={999.99} step={0.01} placeholder="e.g. 3.90" value={item.listPrice} onChange={e => {
                                    var temp = this.state.newOptions;
                                    temp[idx].listPrice = e.target.value;
                                    this.setState({newOptions:temp});
                                }} required />
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor={`optionQuantity_${idx}`} className="fw-bold">Stock Quantity</label>
                                <input type="number" className="form-control me-3" name={`optionQuantity_${idx}`} min={0} max={9999} placeholder="e.g. 50" value={item.quantity} onChange={e => {
                                    var temp = this.state.newOptions;
                                    temp[idx].quantity = e.target.value;
                                    this.setState({newOptions:temp});
                                }} required />
                            </div> */}
                        </div>
                        <div className="has-validation d-flex flex-column ms-3">
                            <div onClick={() => document.getElementById(`optionImage_${idx}`).click()}>{this.getOptionImage(this.state.newOptions[idx])}</div>
                            <input type="file" className="d-none" id={`optionImage_${idx}`} name={`optionImage_${idx}`} accept="image/png, image/jpeg" required={idx===0} onChange={e => {
                                const file = e.target.files[0];
                                if (file) {
                                    document.getElementById("initCropperBtn").click();
                                    setTimeout(() => this.setState({cropperImg:URL.createObjectURL(file),optionCropping:idx}), 500)
                                }
                            }}></input>
                            <span className="invalid-feedback" style={{width:"100px"}}>Please upload an image.</span>
                        </div>
                    </div>
                </li>
            )
        });
    };

    TextAsImage(optionName) {
        const { innerWidth: width } = window;
        var background = new Image();
        background.src = "/optionPlaceholder.jpeg";
        const canvas = createCanvas(width, width);
        const ctx = canvas.getContext("2d");
        ctx.drawImage(background,0,0,width,width);
        ctx.font = "bold 30px Arial";
        ctx.textAlign = "center";
        ctx.fillText(optionName, width*0.65, (width/2)+20, width*0.6);
        return canvas.toDataURL();
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.setState({productQty: Array(this.state.product.options.length).fill(1)});
        document.getElementById("bottomStripMargin").style.height = `${document.getElementById("bottomStrip").offsetHeight}px`;
        const editModal = document.getElementById('editModal');
        editModal.addEventListener('show.bs.modal', event => {
            const button = event.relatedTarget;
            const field = button.getAttribute('data-bs-field');
            const modalTitle = editModal.querySelector('.modal-title');
            const modalForm = editModal.querySelector('.modal-body #modal-form');
            const modalOptionsForm = editModal.querySelector('.modal-body #modal-options-form');
            this.setState({currentlyEditing: field});
            switch (field) {
                case "name": {
                    const name = button.getAttribute('data-bs-name');
                    modalTitle.textContent = "Edit Product Name";
                    modalForm.innerHTML = `<div class="mb-3"><label for="name" class="form-label">Product Name</label><input type="text" class="form-control" name="name" id="name" minLength="1" maxLength="50" placeholder="e.g. Croissant" value="${name}" required /></div>`;
                    break;
                }
                case "description": {
                    const description = button.getAttribute('data-bs-description');
                    modalTitle.textContent = "Edit Product Description";
                    modalForm.innerHTML = `<div class="mb-3"><label for="description" class="form-label">Product Description</label><textarea class="form-control" name="description" id="description" minLength="1" maxLength="300" rows="3" placeholder="e.g. Croissant is a buttery and flaky puff pastry." required>${description}</textarea></div>`;
                    break;
                }
                case "options": {
                    modalTitle.textContent = "Edit Options";
                    this.setState({newOptions: [...this.state.product.options]});
                    modalForm.classList.add("d-none");
                    modalOptionsForm.classList.remove("d-none");
                    //modalForm.innerHTML = this.showOptions(this.state.product.options);
                    break;
                }
                // case "quantity": {
                //     const optionIdx = button.getAttribute('data-bs-option');
                //     const quantity = this.state.product.options[optionIdx].quantity;
                //     const name = this.state.product.options[optionIdx].name;
                //     modalTitle.textContent = `Edit Quantity for ${name} Option`;
                //     modalForm.innerHTML = `<div class="mb-3"><label for="name" class="form-label">Quantity for ${name} Option</label><input type="number" class="form-control" name="quantity" id="quantity" min="0" max="9999" placeholder="e.g. 50" value="${quantity}" required /></div>`;
                //     break;
                // }
                // case "price": {
                //     const optionIdx = button.getAttribute('data-bs-option');
                //     const costPrice = this.state.product.options[optionIdx].costPrice;
                //     const listPrice = this.state.product.options[optionIdx].listPrice;
                //     const name = this.state.product.options[optionIdx].name;
                //     modalTitle.textContent = `Edit Prices for ${name} Option`;
                //     modalForm.innerHTML = `<div class="mb-3"><label for="costPrice" class="form-label">Cost Price for ${name} Option</label><input type="number" class="form-control" name="costPrice" id="costPrice" min="0.01" max="999.99" step="0.01" placeholder="e.g. 4.40" value="${costPrice}" required /><br/><label for="listPrice" class="form-label">List Price for ${name} Option</label><input type="number" class="form-control" name="listPrice" id="listPrice" min="0.01" max="999.99" step="0.01" placeholder="e.g. 3.90" value="${listPrice}" required /></div>`;
                //     break;
                // }
                case "reserve": {
                    const reserve = button.getAttribute('data-bs-reserve');
                    modalTitle.textContent = "Edit Reservable Status";
                    modalForm.innerHTML = `<div class="mb-3"><label for="reserve" class="form-label">Allow product to be reserved</label><div class="form-check form-switch"><input type="checkbox" class="form-check-input" role="switch" name="reserve" id="reserve" checked="${reserve}" required /></div></div>`;
                    break;
                }
            }
        });
        editModal.addEventListener('hide.bs.modal', event => {
            const modalForm = editModal.querySelector('.modal-body #modal-form');
            const modalOptionsForm = editModal.querySelector('.modal-body #modal-options-form');
            modalForm.classList.remove("d-none");
            modalOptionsForm.classList.add("d-none");
        });
    };

    getProduct() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        const query = this.props.query;
        fetch(`${constants.API_BASE_URL}/customer/product`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                product: this.props.id
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                
            }
        });
    };

    addToCartBtn() {
        const quantityExist = this.state.product.options[this.state.carouselIndex].quantity > 0;
        if (quantityExist || this.state.product.reserve) {
            return <button className="btn btn-primary" onClick={() => this.addToCart()}>{quantityExist ? "Add to Cart" : "Reserve"}</button>
        } else {
            return <button className="btn btn-muted">Sold Out</button>
        };
    };

    addToCart() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/cart/add`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                shop: this.state.product.shop,
                product: this.props.id,
                option: this.state.product.options[this.state.carouselIndex]._id,
                quantity: this.state.productQty[this.state.carouselIndex]
            })
        }).then(async res => await res.json())
        .then(res => {
            console.log(res);
            if (res.ok) {
                this.setState({toast: {
                    header: "Product added to cart",
                    body: <button className="btn btn-primary" onClick={() => this.props.navigate("/customer/cart")}>Go to Cart</button>
                }})
            }
        });
    };
    editField() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        const currentlyEditing = this.state.currentlyEditing;
        var data = {};
        const formData = new FormData(document.getElementById("modal-form"));
        const optionsFormData = new FormData(document.getElementById("modal-options-form"));
        switch (currentlyEditing) {
            case "reserve": {
                data = {reserve:!!formData.get("reserve")}
                break;
            }
        };
        console.log(data)
        fetch(`${constants.API_BASE_URL}/merchant/product/edit/${currentlyEditing}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                shop: this.state.product.shop,
                product: this.props.id,
                data
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.props.navigate("?refresh=true");
            }
        });
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <ToastContainer position="top-center" className="mt-3" style={{zIndex:1010}}>
                    <Toast show={Object.keys(this.state.toast).length} onClose={() => this.setState({toast:{}})}>
                        <Toast.Header>
                            <strong className="me-auto">{this.state.toast.header}</strong>
                        </Toast.Header>
                        <Toast.Body>{this.state.toast.body}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editModalLabel">Label</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id="modal-form" onSubmit={e => e.preventDefault()}></form>
                            <form id="modal-options-form" className="d-none" onSubmit={e => e.preventDefault()}>
                                {this.showOptions()}
                                <button type="button" className={`btn btn-outline-primary mt-3 ${this.state.newOptions.length===5?'d-none':''}`} onClick={() => {
                                    const temp = this.state.newOptions;
                                    const defaultOption = document.getElementById('optionName_0');
                                    if (defaultOption.value === "Default") defaultOption.value = '';
                                    temp.push({name:undefined,costPrice:undefined,listPrice:undefined,quantity:undefined,image:undefined});
                                    this.setState({newOptions:temp});
                                }}>Add Option</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="editModelCancelBtn" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" id="editModelSaveBtn" className="btn btn-primary" onClick={() => this.editField()}>Save</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Product</h1>
                    </div>
                    <Carousel activeIndex={this.state.carouselIndex} interval={null} onSelect={(carouselIndex, e) => this.setState({carouselIndex})} style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        {this.state.product.options.map((item, idx) => {
                            return (<Carousel.Item key={idx}>
                                <img
                                className="d-block w-100"
                                src={item.media ? `${constants.PRODUCT_MEDIA_BASE_URL}/${item.media.filename}` : this.TextAsImage(item.name)}
                                alt={item.name}
                                />
                                <Carousel.Caption>{item.name}</Carousel.Caption>
                            </Carousel.Item>)
                        })}
                    </Carousel>
                    <div className="d-flex align-items-center mt-3">
                        <h1 className="text-body">{this.state.product.name}</h1>
                        <FontAwesomeIcon icon={faEdit} className="text-primary ms-3 mb-2" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="name" data-bs-name={this.state.product.name} />
                    </div>
                    <div className="d-flex">
                        <p>{this.state.product.description}</p>
                        <FontAwesomeIcon icon={faEdit} className="text-primary ms-2 mt-1" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="description" data-bs-description={this.state.product.description} />
                    </div>
                    <div className="d-flex flex-column">
                        <a className="d-flex text-black text-decoration-none" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="options">
                            <strong>Options</strong>
                            <FontAwesomeIcon icon={faEdit} className="text-primary ms-2 mt-1" />
                        </a>
                        <div className="mt-2">
                            {this.state.product.options.map((item, idx) => {
                                return (
                                    <button key={idx} className={`btn ${idx===this.state.carouselIndex ? "btn-primary" : "btn-outline-primary"} col-3 me-3 ${idx>2 ? "mt-2" : ""}`} onClick={() => this.setState({carouselIndex:idx})}>{item.name}</button>
                                )
                            })}
                        </div>
                        <div className="d-flex mt-3 mb-1" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="options">
                            <strong>Quantity</strong>
                            <FontAwesomeIcon icon={faEdit} className="text-primary ms-2 mt-1" />
                        </div>
                        <div className="d-flex align-items-baseline">
                            {/* <QuantityInput value={this.state.productQty[this.state.carouselIndex]} decrease={() => {
                                const temp = this.state.productQty;
                                temp[this.state.carouselIndex] -= 1;
                                this.setState({productQty:temp});
                            }} increase={() => {
                                const temp = this.state.productQty;
                                temp[this.state.carouselIndex] += 1;
                                this.setState({productQty:temp});
                            }} max={this.state.product.reserve?100:this.state.product.options[this.state.carouselIndex].quantity} /> */}
                            <span className="text-nowrap">{this.state.product.options[this.state.carouselIndex].quantity} in stock</span>
                        </div>
                        <div className="d-flex mt-3 mb-1" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="reserve" data-bs-name={this.state.product.reserve}>
                            <strong>Allow product to be reserved</strong>
                            <FontAwesomeIcon icon={faEdit} className="text-primary ms-2 mt-1" />
                        </div>
                        <span>{this.state.product.reserve ? "Yes" : "No"}</span>
                    </div>
                    {/* <strong className="mt-3">Shop</strong>
                    <div className="d-flex flex-column bg-light p-3 mt-1" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <strong>{this.state.product.shop.displayName}</strong>
                        <span>{this.state.product.shop.shop.address.street}{this.state.product.shop.shop.address.unitNo ? `, ${this.state.product.shop.shop.address.unitNo}` : ''}, {this.state.product.shop.shop.address.postalCode}</span>
                    </div> */}
                    <div id="bottomStripMargin" />
                    <div id="bottomStrip" className="d-flex align-items-center bg-light fixed-bottom p-2" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <div className="ms-3" data-bs-toggle="modal" data-bs-target="#editModal" data-bs-field="options">  
                            <strong>Price</strong>
                            <FontAwesomeIcon icon={faEdit} className="text-primary ms-2 mt-1" />
                        </div>
                        <div className="d-flex ms-auto me-3 align-items-center">
                            <span className="text-decoration-line-through" style={{fontSize:'0.75em'}}>${this.state.product.options[this.state.carouselIndex].costPrice.toFixed(2)}</span>
                            <h2 className="mt-2 ms-3 me-3">${(this.state.product.options[this.state.carouselIndex].listPrice * this.state.productQty[this.state.carouselIndex]).toFixed(2)}</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let {id} = useParams();
    let {state} = useLocation();
    console.log(state)
    return <Product {...props} navigate={navigate} product={state} id={id} />
}

export default WithNavigate; 