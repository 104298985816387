import {faCartPlus, faChevronLeft, faShop, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import constants from '../constants';
import InfiniteScroll from 'infinite-scroll';

class SearchResults extends React.Component {
    constructor() {
        super();
        this.state = {
            products: [],
            shops: []
        };
        this.search = this.search.bind(this);
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.search(0, false);
        try {
            let productsInfiniteScroll = new InfiniteScroll("#productsList", {
                // options
                path: '.pagination__next',
                append: '.card',
                status: '.scroller-status',
                hideNav: '.pagination',
            });
        } catch {}
    };

    search(page, returnResults) {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        const query = this.props.query;
        fetch(`${constants.API_BASE_URL}/customer/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                query,
                page
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                if (returnResults) {
                    return {products:res.data.results.products, shops:res.data.results.shops};
                } else {
                    this.setState({products:res.data.results.products, shops:res.data.results.shops});
                }
            }
        });
    };

    changeTab(e) {
        const clicked = e.currentTarget;
        const clickedId = clicked.id;
        if (!clicked.classList.contains("active")) {
            document.querySelector(".navTab.active").classList.remove("active");
            document.getElementById(clickedId).classList.add("active");
        };
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <input type="text" className="form-control ms-3" id="search" placeholder="Search" value={this.props.query} onClick={() => this.props.navigate(-1)} readOnly />
                    </div>
                    <ul className="nav nav-pills nav-fill">
                        <li className="nav-item">
                            <a className="navTab nav-link active" id="tab_1" onClick={e => this.changeTab(e)}>Products</a>
                        </li>
                        <li className="nav-item">
                            <a className="navTab nav-link" id="tab_2" onClick={e => this.changeTab(e)}>Shops</a>
                        </li>
                    </ul>
                    <br />
                    <div id="productsList" className="d-flex flex-wrap justify-content-between">
                        {this.state.products ? this.state.products.map((item, idx) => {
                            const onlyOneOption = item.options.length === 1;
                            var [lowestCostPrice, lowestListPrice] = Array(3).fill(Infinity);
                            var [highestCostPrice, highestListPrice] = Array(3).fill(0);
                            item.options.forEach(option => {
                                if (option.costPrice < lowestCostPrice) {
                                    lowestCostPrice = option.costPrice;
                                };
                                if (option.listPrice < lowestListPrice) {
                                    lowestListPrice = option.listPrice;
                                };
                                if (option.costPrice > highestCostPrice) {
                                    highestCostPrice = option.costPrice;
                                };
                                if (option.listPrice > highestListPrice) {
                                    highestListPrice = option.listPrice;
                                };
                            });
                            return (
                                <div id={`product_${item._id}`} className="card mb-3" style={{width:"48%"}} key={idx} onClick={() => this.props.navigate(`/customer/product/${item._id}`, {state:item})}>
                                    <img className="card-img-top" src={`${constants.PRODUCT_MEDIA_BASE_URL}/${item.options[0].media.filename}`} />
                                    <div className="card-body">
                                        <div className="text-muted" style={{fontSize:'0.75em'}}>
                                            <FontAwesomeIcon icon={faShop} />
                                            <span className="ms-2">{item.shop.displayName}</span>
                                        </div>
                                        <h5 className="card-title mt-2">{item.name}</h5>
                                        <div className="d-flex flex-column">
                                            <span className="text-decoration-line-through text-muted" style={{fontSize:'0.75em'}}>${lowestCostPrice.toFixed(2)}{!onlyOneOption?` - ${highestCostPrice.toFixed(2)}`:''}</span>
                                            <span className="fs-5 fw-bold">${lowestListPrice.toFixed(2)}{!onlyOneOption?` - ${highestListPrice.toFixed(2)}`:''}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <></>}
                    </div>
                    <div className="scroller-status">
                        <div className="infinite-scroll-request loader-ellips">
                        </div>
                        <p className="infinite-scroll-last text-muted text-center">- End -</p>
                    </div>
                    <p className="pagination">
                        <a className="pagination__next" href="page2.html">Next page</a>
                    </p>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let { query } = useParams();
    return <SearchResults {...props} navigate={navigate} query={query} />
}

export default WithNavigate; 