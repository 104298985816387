import {faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createCanvas } from 'canvas';
import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import constants from '../constants';
import '../fnbgg.css';

class ShopCart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartItem: props.cartItem,
            subtotal: 0,
            voucher: {
                code: "",
                amount: 0
            },
            balance: 0,
            byo: false,
            byoDiscount: 0,
            refreshBackgroundImage: 0
        };
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.calculateSubtotal();
        this.getBalance();
    };

    getCartItem() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/cart/retrieveShop`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                
            } else {

            };
        });
    };

    calculateSubtotal() {
        var subtotal = 0;
        this.state.cartItem.products.forEach(item => {
            item.product.options.forEach(option => {
                subtotal += option.listPrice*item.quantity;
            });
        });
        this.setState({subtotal});
    };

    calculateSavings() {
        var costSubtotal = 0;
        var listSubtotal = 0;
        var byoDiscount = 0;
        this.state.cartItem.products.forEach(item => {
            item.product.options.forEach(option => {
                costSubtotal += option.costPrice*item.quantity;
                listSubtotal += option.listPrice*item.quantity;
                byoDiscount += this.state.cartItem.shop.shop.byoDiscount;
            });
        });
        if (isNaN(byoDiscount)) {
            byoDiscount = 0;
        };
        const difference = costSubtotal - listSubtotal;
        const savings = this.state.byo ? difference + byoDiscount + this.state.voucher.amount : difference + this.state.voucher.amount;
        return savings.toFixed(2);
    };

    TextAsImage(optionName) {
        const { innerWidth: width } = window;
        const canvas = createCanvas(width, width);
        const ctx = canvas.getContext("2d");
        const background = new Image();
        background.src = "/optionPlaceholder.jpeg";
        ctx.drawImage(background,0,0,width,width);
        ctx.font = "bold 30px Arial";
        ctx.textAlign = "center";
        ctx.fillText(optionName, width*0.65, (width/2)+20, width*0.6);
        background.addEventListener("load", () => this.setState({refreshBackgroundImage:Math.random()}));
        return canvas.toDataURL();
    };

    getBalance() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/balance/retrieve`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.setState({
                    balance: res.data.balance,
                })
            } else {

            }
        });
    };

    checkout() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/cart/checkout`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                shop: this.props.id,
                products: this.state.cartItem.products,
                byo: this.state.byo,
                voucher: this.state.voucher.code
            })
        }).then(async res => await res.json())
        .then(res => {
            console.log(res);
            if (res.ok) {
                this.props.navigate(`/customer/orders/cart/${res.data.cartId}`);
            } else {

            };
        });
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Cart</h1>
                    </div>
                    <div className="d-flex flex-column bg-light p-3 mt-1 mb-3" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <strong>{this.state.cartItem.shop.displayName}</strong>
                        <span>{this.state.cartItem.shop.shop.address.street}</span>
                        {this.state.cartItem.shop.shop.address.unitNo?<span>{this.state.cartItem.shop.shop.address.unitNo}</span>:<></>}
                    </div>
                    <strong>Items</strong>
                    <div className="d-flex flex-column bg-light mt-2 mb-3" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        {this.state.cartItem.products.map((item, idx) => {
                            var currentOption = {media:{}};
                            item.product.options.forEach(option => {
                                if (option._id === item.option) {
                                    currentOption = option;
                                };
                            });
                            const lastOption = this.state.cartItem.products.length === idx + 1;
                            const itemTotal = currentOption.costPrice*item.quantity;
                            return (
                                <div key={idx} className={`d-flex ${lastOption ? "" : "border-bottom"}`}>
                                    <img
                                    className="d-block"
                                    style={{width:100, height:100}}
                                    src={currentOption.media ? `${constants.PRODUCT_MEDIA_BASE_URL}/${currentOption.media.filename}` : this.TextAsImage(currentOption.name)}
                                    alt={currentOption.name}
                                    />
                                    <div className="d-flex flex-grow-1">
                                        <div className="d-flex flex-column mx-3 justify-content-center">
                                            <strong>{item.product.name}</strong>
                                            <span>Option: {currentOption.name}</span>
                                            <span>${currentOption.costPrice.toFixed(2)} x {item.quantity}</span>
                                        </div>
                                        <div className="d-flex flex-column my-3 ms-auto me-3">
                                            <span className="text-primary text-end">Edit</span>
                                            <h4 className="mt-1">${itemTotal.toFixed(2)}</h4>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <strong>Bring Your Own Container and Cutlery</strong>
                    <div className={`d-flex flex-column ${this.state.byo ? "fnbggBgGreen" : "bg-light"} my-2 p-3`} style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <div>
                            <div className="form-check form-switch">
                                <label htmlFor="bringYourOwn">Bringing your own container and cutlery?</label>
                                <input className="form-check-input" type="checkbox" role="switch" id="bringYourOwn" onChange={e => this.setState({byo:e.target.checked})} />
                            </div>
                            <span className="text-muted">{this.state.cartItem.shop.shop.byoDiscount ? `$${this.state.cartItem.shop.shop.byoDiscount.toFixed(2)} discount per item` : "Discount not applicable for this shop"}</span>
                        </div>
                    </div>
                    <strong>Cart Total</strong>
                    <div className="d-flex flex-column bg-light my-2 p-3" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <div className="d-flex justify-content-between">
                            <span>Subtotal</span>
                            <span>${this.state.subtotal.toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Voucher (<span className="text-primary">{this.state.voucher.code ? "Remove" : "Enter Code"}</span>)</span>
                            <span>- ${this.state.voucher.amount.toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>"Bring Your Own" Discount</span>
                            <span>- ${this.state.voucher.amount.toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between mt-3">
                            <h3>Total</h3>
                            <h3>${(this.state.subtotal-this.state.voucher.amount-this.state.byoDiscount).toFixed(2)}</h3>
                        </div>
                        <span className="text-muted">You are saving ${this.calculateSavings()}</span>
                    </div>
                    <strong>Payment</strong>
                    <div className="d-flex flex-column bg-light my-2 p-3" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <div className="d-flex justify-content-between">
                            <span>Available Balance</span>
                            <span>${this.state.balance.toFixed(2)}</span>
                        </div>
                        {this.state.balance < (this.state.subtotal-this.state.voucher.amount-this.state.byoDiscount) ? <span className="text-danger"><span onClick={() => this.navigate("/balance")} className="text-primary">Top-up your wallet</span> to continue with checkout</span> : <></>}
                    </div>
                    <span>By checking out, you agree to our <a href="">terms and conditions</a>.</span>
                    <button className="btn btn-primary w-100 my-3" onClick={() => this.checkout()} disabled={this.state.balance < (this.state.subtotal-this.state.voucher.amount-this.state.byoDiscount)}>Checkout</button>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let {state} = useLocation();
    let {id} = useParams();
    return <ShopCart {...props} navigate={navigate} cartItem={state} id={id}  />
}

export default WithNavigate; 