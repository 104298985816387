import { faCartShopping, faChevronLeft, faLocationDot, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import constants from './constants';

class Balance extends React.Component {
    constructor() {
        super();
        this.state = {
            balance: 0,
            history: [],
            topupAmount: 10
        };
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.getBalance();
    };

    getBalance() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/balance/retrieve?history=true`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.setState({
                    balance: res.data.balance,
                    history: res.data.history
                })
            } else {

            }
        });
    };

    topup() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/balance/topup`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                amount: this.state.topupAmount
            })
        }).then(async res => await res.json())
        .then(res => {
            console.log(res)
            if (res.ok) {
                window.location.href = res.data.redirect;
            } else {

            }
        });
    };

    getAmount(item) {
        const type = item.type;
        const amount = item.amount;
        if (type.includes("TOPUP") || type.includes("REFUND")) {
            return `+ $${amount.toFixed(2)}`
        } else {
            return `- $${amount.toFixed(2)}`
        }
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Credit Balance</h1>
                    </div>
                    <strong>Current Balance</strong>
                    <div className="bg-light p-3 mt-1 mb-3">
                        <h1>${this.state.balance.toFixed(2)}</h1>
                    </div>
                    <strong>Top-up</strong>
                    <div className="bg-light p-3 mt-1 mb-3">
                        <div className="d-flex justify-content-between">
                            <button className={`btn ${this.state.topupAmount === 10 ? "btn-info" : "btn-outline-secondary"}`} onClick={() => this.setState({topupAmount:10})}>$10</button>
                            <button className={`btn ${this.state.topupAmount === 20 ? "btn-info" : "btn-outline-secondary"}`} onClick={() => this.setState({topupAmount:20})}>$20</button>
                            <button className={`btn ${this.state.topupAmount === 30 ? "btn-info" : "btn-outline-secondary"}`} onClick={() => this.setState({topupAmount:30})}>$30</button>
                            <button className={`btn ${this.state.topupAmount === 40 ? "btn-info" : "btn-outline-secondary"}`} onClick={() => this.setState({topupAmount:40})}>$40</button>
                            <button className={`btn ${this.state.topupAmount === 50 ? "btn-info" : "btn-outline-secondary"}`} onClick={() => this.setState({topupAmount:50})}>$50</button>
                        </div>
                        <button className="btn btn-primary w-100 mt-3" onClick={() => this.topup()}>Top-up</button>
                    </div>
                    <strong>History</strong>
                    <div className="bg-light p-3 mt-1">
                        {this.state.history.map((item, idx) => {
                            return (
                                <div key={idx} className="d-flex flex-column mb-3 border-bottom">
                                    <strong>{item.description}</strong>
                                    <span className="text-muted">Ref: {item._id}</span>
                                    <span className="text-muted">Date: {new Date(item.createdOn).toLocaleString('en-GB', { timeZone: 'Asia/Singapore' })}</span>
                                    <h1 className="ms-auto mt-3">{this.getAmount(item)}</h1>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    return <Balance {...props} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} />
}

export default WithNavigate; 