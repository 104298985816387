import React from 'react';
import QRCode from 'qrcode';

class JoinShop extends React.Component {
    constructor() {
        super();
    };

    componentDidMount() {
        const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        try {
            if (initDataUnsafe) {
                const userId = initDataUnsafe.user.id;
                document.querySelector('#userId').innerHTML = `User ID<br/><strong>${userId}</strong>`;
                const canvas = document.querySelector('#qrCode');
                QRCode.toCanvas(canvas, `${userId}`, function (error) {
                    if (error) console.error(error)
                });
            } else {
                document.querySelector('#sessionExpired').classList.remove('d-none');
                document.querySelector('.container').classList.add('d-none');
            }
        } catch {}
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="">Join Shop</h1>
                    <canvas id="qrCode"></canvas>
                    <span id="userId" className='text-center'></span>
                    <br/>
                    <span className="text-muted text-center">Present this QR code to your shop supervisor for scanning.<br/><br/>Alternatively, they may enter your User ID manually.</span>
                </div>
            </div>
        );
    };
};

export default JoinShop;