import {faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import constants from '../constants';

class Search extends React.Component {
    constructor() {
        super();
        this.state = {
            showSuggestions: false,
            popular: [],
            suggestions: []
        };
        this.searchSuggestions = this.searchSuggestions.bind(this);
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        try {
        } catch {}
    };

    searchSuggestions(e) {
        const query = e.target.value;
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        if (query) {
            this.setState({showSuggestions:true});
            fetch(`${constants.API_BASE_URL}/customer/search/suggestions`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    initData,
                    query
                })
            }).then(async res => await res.json())
            .then(res => {
                if (res.ok) {
                    this.setState({suggestions:res.data.results});
                }
            });
        } else {
            this.setState({showSuggestions:false});
        };
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <input type="text" className="form-control ms-3" id="search" placeholder="Search" autoFocus={true} onChange={this.searchSuggestions} />
                    </div>
                    <div id="suggestionsGrp">
                        <div className="d-flex align-items-center">
                            <span id="suggestionsTitle" className="fw-bold">{this.state.showSuggestions?"Suggestions":"Popular"}</span>
                        </div>
                        <div id="suggestions" className="mt-2" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                            {this.state.showSuggestions ? this.state.suggestions.map((item, idx) => {
                                return <div className="border-top border-bottom p-3" key={idx} onClick={() => this.props.navigate(item.name)}>{item.name}</div>
                            }) : this.state.popular.map((item, idx) => {
                                return <div className="border-top border-bottom p-3" key={idx} onClick={() => this.props.navigate(item.name)}>{item.name}</div>
                            })}
                        </div>
                    </div>
                    <div id="history" className="d-none">
                        <div className="d-flex align-items-center">
                            <span className="fw-bold">History</span>
                            <FontAwesomeIcon icon={faTrash} className="text-primary ms-auto" />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    return <Search {...props} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} />
}

export default WithNavigate; 