import { faCartShopping, faChevronLeft, faLocationDot, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, ToastContainer, Toast } from 'react-bootstrap';
import React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import constants from '../constants';
import { createCanvas } from 'canvas';
import QuantityInput from '../component/QuantityInput';

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselIndex: 0,
            product: props.product,
            productQty: [],
            toast: {}
        };
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.setState({productQty: Array(this.state.product.options.length).fill(1)});
        document.getElementById("bottomStripMargin").style.height = `${document.getElementById("bottomStrip").offsetHeight}px`;
    };

    getProduct() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        const query = this.props.query;
        fetch(`${constants.API_BASE_URL}/customer/product`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                product: this.props.id
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                
            }
        });
    };

    TextAsImage(optionName) {
        const { innerWidth: width } = window;
        var background = new Image();
        background.src = "/optionPlaceholder.jpeg";
        const canvas = createCanvas(width, width);
        const ctx = canvas.getContext("2d");
        ctx.drawImage(background,0,0,width,width);
        ctx.font = "bold 30px Arial";
        ctx.textAlign = "center";
        ctx.fillText(optionName, width*0.65, (width/2)+20, width*0.6);
        return canvas.toDataURL();
    };

    addToCartBtn() {
        const quantityExist = this.state.product.options[this.state.carouselIndex].quantity > 0;
        if (quantityExist || this.state.product.reserve) {
            return <button className="btn btn-primary" onClick={() => this.addToCart()}>{quantityExist ? "Add to Cart" : "Reserve"}</button>
        } else {
            return <button className="btn btn-muted">Sold Out</button>
        };
    };

    addToCart() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/cart/add`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                shop: this.state.product.shop,
                product: this.props.id,
                option: this.state.product.options[this.state.carouselIndex]._id,
                quantity: this.state.productQty[this.state.carouselIndex]
            })
        }).then(async res => await res.json())
        .then(res => {
            console.log(res);
            if (res.ok) {
                this.setState({toast: {
                    header: "Product added to cart",
                    body: <button className="btn btn-primary" onClick={() => this.props.navigate("/customer/cart")}>Go to Cart</button>
                }})
            }
        });
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <ToastContainer position="top-center" className="mt-3" style={{zIndex:1010}}>
                    <Toast show={Object.keys(this.state.toast).length} onClose={() => this.setState({toast:{}})}>
                        <Toast.Header>
                            <strong className="me-auto">{this.state.toast.header}</strong>
                        </Toast.Header>
                        <Toast.Body>{this.state.toast.body}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Product</h1>
                        <div className="ms-auto me-3">
                            <FontAwesomeIcon icon={faSearch} className="fs-3 text-primary" onClick={() => this.props.navigate("/customer/search")} />
                            <FontAwesomeIcon icon={faCartShopping} className="fs-3 ms-4 text-primary" onClick={() => this.props.navigate("/customer/cart")} />
                        </div>
                    </div>
                    <Carousel activeIndex={this.state.carouselIndex} interval={null} onSelect={(carouselIndex, e) => this.setState({carouselIndex})} style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        {this.state.product.options.map((item, idx) => {
                            return (<Carousel.Item key={idx}>
                                <img
                                className="d-block w-100"
                                src={item.media ? `${constants.PRODUCT_MEDIA_BASE_URL}/${item.media.filename}` : this.TextAsImage(item.name)}
                                alt={item.name}
                                />
                                <Carousel.Caption>{item.name}</Carousel.Caption>
                            </Carousel.Item>)
                        })}
                    </Carousel>
                    <h1 className="text-body mt-3">{this.state.product.name}</h1>
                    <p>{this.state.product.description}</p>
                    <div>

                    </div>
                    <div className="d-flex flex-column">
                        <strong>Options</strong>
                        <div className="mt-1">
                            {this.state.product.options.map((item, idx) => {
                                return (
                                    <button key={idx} className={`btn ${idx===this.state.carouselIndex ? "btn-primary" : "btn-outline-primary"} col-3 me-3 ${idx>2 ? "mt-2" : ""}`} onClick={() => this.setState({carouselIndex:idx})}>{item.name}</button>
                                )
                            })}
                        </div>
                        <strong className="mt-3 mb-1">Quantity</strong>
                        <div className="d-flex align-items-baseline">
                            <QuantityInput value={this.state.productQty[this.state.carouselIndex]} decrease={() => {
                                const temp = this.state.productQty;
                                temp[this.state.carouselIndex] -= 1;
                                this.setState({productQty:temp});
                            }} increase={() => {
                                const temp = this.state.productQty;
                                temp[this.state.carouselIndex] += 1;
                                this.setState({productQty:temp});
                            }} max={this.state.product.reserve?100:this.state.product.options[this.state.carouselIndex].quantity} />
                            <span className="ms-3 text-nowrap">{this.state.product.options[this.state.carouselIndex].quantity} left</span>
                        </div>
                    </div>
                    <strong className="mt-3">Shop</strong>
                    <div className="d-flex flex-column bg-light p-3 mt-1" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <strong>{this.state.product.shop.displayName}</strong>
                        <span>{this.state.product.shop.shop.address.street}{this.state.product.shop.shop.address.unitNo ? `, ${this.state.product.shop.shop.address.unitNo}` : ''}, {this.state.product.shop.shop.address.postalCode}</span>
                    </div>
                    <div id="bottomStripMargin" />
                    <div id="bottomStrip" className="d-flex align-items-center bg-secondary fixed-bottom p-2" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <div className="d-flex align-items-end">
                            <div className="d-flex flex-column align-items-center ms-3">
                                <span className="text-decoration-line-through text-light" style={{fontSize:'0.75em'}}>${this.state.product.options[this.state.carouselIndex].costPrice.toFixed(2)}</span>
                                <span className="text-white">${this.state.product.options[this.state.carouselIndex].listPrice.toFixed(2)}</span>
                            </div>
                            <span className="text-white ms-2">x{this.state.productQty[this.state.carouselIndex]}</span>
                        </div>
                        <div className="d-flex ms-auto me-3 align-items-center">
                            <h2 className="text-white mt-2 ms-3 me-3">${(this.state.product.options[this.state.carouselIndex].listPrice * this.state.productQty[this.state.carouselIndex]).toFixed(2)}</h2>
                            {this.addToCartBtn()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let {id} = useParams();
    let {state} = useLocation();
    return <Product {...props} navigate={navigate} product={state} id={id} />
}

export default WithNavigate; 