import { faBoxesStacked, faChartColumn, faClipboard, faStore } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomNav from "../component/BottomNav";
import constants from "../constants";
import CreateShopTransient from "./CreateShopTransient";

function MerchantMain() {
    const [state, setState] = useState('loading');

    const navItems = [
        {
            icon: faChartColumn,
            title: "Overview",
            component: () => {}
        },
        {
            icon: faClipboard,
            title: "Orders",
            component: () => {}
        },
        {
            icon: faBoxesStacked,
            title: "Products",
            component: () => {}
        },
        {
            icon: faStore,
            title: "Shop",
            component: <CreateShopTransient screen="prompt" />
            // <div className="h-100 w-100">
            //     <div className="spinner-border position-fixed" style={{top:'50%',left:'50%'}} role="status">
            //         <span className="sr-only">Loading...</span>
            //     </div>
            //     <iframe src="/merchant/shop/createtransient/prompt" title="Shop" className="h-100 w-100 position-absolute" style={{zIndex:99}}></iframe>
            // </div>
        },
    ]

    useEffect(() => {
        // fetch(`${constants.API_BASE_URL}/shop/retrieveAll`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         initData: window.Telegram.WebApp.initData,
        //     })
        // }).then(async res => await res.json())
        // .then(data => {
        //     console.log(data);
        //     // if (data.ok) {
        //     //     if (data.data.shops.length > 0) {
        //     //         //navigate("/merchant/shop/createtransient/prompt");
        //     //     } else {
        //     //         navigate("/merchant/shop/createtransient/prompt");
        //     //     }
        //     // } else {
        //     //     console.log('test');
        //     // };
        // })
        // .catch(err => {
        //     console.log(err);
        // });
    }, []);

    if (state === "loading") {
        return (
            <div className="spinner-border position-fixed" style={{top:'50%',left:'50%'}} role="status">
                {/* <span className="sr-only">Loading...</span> */}
            </div>
        )
    }
    // <BottomNav items={navItems} />
}

export default MerchantMain;