import {faChevronLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import constants from '../constants';

class Cart extends React.Component {
    constructor() {
        super();
        this.state = {
            cart: []
        };
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.getCart();
    };

    getCart() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/cart/retrieveAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                if (res.data.cart.length === 1) {
                    this.props.navigate(`shop/${res.data.cart[0].shop._id}`, {state:res.data.cart[0], replace:true});
                } else {
                    this.setState({cart:res.data.cart});
                };
            } else {

            };
        });
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Cart</h1>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    return <Cart {...props} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} />
}

export default WithNavigate; 