import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import constants from '../constants';

class ManageMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            shop: {displayName:''},
            productCategories: [],
            errors: [],
            editingCategory: undefined,
            deleteBtnDisabled: true,
            toast: {}
        };
    };

    componentDidMount() {
        const initData = "d" //window.Telegram.WebApp.initData;
        this.getMenu();
    };

    getMenu() {
        this.setState({loadingCategory:true});
        fetch(`${constants.API_BASE_URL}/merchant/product/retrieveAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69", //window.Telegram.WebApp.initData,
                shop: this.props.id
            })
        }).then(async res => await res.json())
        .then(data => {
            if (data.ok) {
                this.setState({shop:data.data.shop,productCategories:data.data.productCategories,loading:false});
            } else {
                this.setState({formErrors:typeof data.err === 'object'?[...data.err]:[data.err],loading:false});
            };
        })
        .catch(err => {
            this.setState({formErrors:err?[err.message]:'• An unexpected error occurred, please try again',loading:false});
        });
    };

    createCategory() {
        const createBtn = document.querySelector('#createCategoryFormCreateBtn');
        const form = document.querySelector('#createCategoryForm');
        const formData = new FormData(form);
        var object = {
            initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69", //window.Telegram.WebApp.initData, 
            shop: this.props.id
        };
        formData.forEach((value, key) => object[key] = value);
        var json = JSON.stringify(object);
        if (form.checkValidity()) {
            createBtn.setAttribute("disabled", true);
            createBtn.innerHTML = `Creating...`;
            fetch(`${constants.API_BASE_URL}/merchant/product/category/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: json
            }).then(async res => await res.json())
            .then(data => {
                console.log(data)
                createBtn.removeAttribute("disabled");
                createBtn.innerHTML = `Create`;
                if (data.ok) {
                    this.getMenu();
                    document.querySelector(".modal").click()
                } else {
                    this.setState({formErrors:typeof data.err === 'object'?[...data.err]:[data.err]});
                };
            })
            .catch(err => {
                console.log(err)
                createBtn.removeAttribute("disabled");
                createBtn.innerHTML = `Create`;
                this.setState({formErrors:err?[err.message]:'• An unexpected error occurred, please try again'});
            });
        } else {
            form.classList.add("was-validated");
        };
    };

    deleteCategory() {
        document.getElementById("deleteConfirmationFormItemDeleting").innerHTML = `You are deleting your product category <code>${this.state.editingCategory.name}</code>. <span class="text-danger">This action is irreversible!</span>`;
        const backBtn = document.getElementById("deleteConfirmationFormBackBtn");
        backBtn.setAttribute("data-bs-toggle", "modal");
        backBtn.setAttribute("data-bs-target", "#createCategoryModal");
    };

    confirmDeleteCategory() {
        const deleteBtn = document.getElementById("deleteConfirmationFormDeleteBtn");
        deleteBtn.setAttribute("disabled", true);
        deleteBtn.innerText = "Deleting";
        fetch(`${constants.API_BASE_URL}/merchant/product/category/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69",
                category: this.state.editingCategory
            })
        }).then(async res => await res.json())
        .then(res => {
            deleteBtn.removeAttribute("disabled");
            deleteBtn.innerText = "Delete";
            console.log(res)
            if (res.ok) {
                document.querySelector(".modal").click();
                this.getMenu();
            } else {

            };
        })
        .catch(err => {
            deleteBtn.removeAttribute("disabled");
            deleteBtn.innerText = "Delete";
        });
    };

    renderDeleteCategoryTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Please delete all products under this category first
        </Tooltip>
      );

      DeleteCategoryBtn = () => {
          if (this.state.editingCategory) {
            const disabled = this.state.editingCategory.products.length > 0;
            if (disabled) {
                return (
                    <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderDeleteCategoryTooltip}>
                        <div className="me-auto">
                            <button type="button" id="createCategoryFormBackBtn" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal" disabled={disabled}>Delete</button>
                        </div>
                    </OverlayTrigger>
                )
            } else {
                return <button type="button" id="createCategoryFormBackBtn" className="btn btn-danger me-auto" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal" onClick={() => this.deleteCategory()} disabled={disabled}>Delete</button>;
            }
          } else {
            return <></>
          }
      }

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <ToastContainer position="top-center" className="mt-3">
                    <Toast show={Object.keys(this.state.toast).length} onClose={() => this.setState({toast:{}})}>
                        <Toast.Header>
                            <strong className="me-auto">{this.state.toast.header}</strong>
                        </Toast.Header>
                        <Toast.Body>{this.state.toast.body}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="modal fade" id="deleteConfirmationModal" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Delete Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({editingCategory:undefined})}></button>
                        </div>
                        <div className="modal-body">
                            <form id="deleteConfirmationForm">
                                <div className="form-group">
                                    <p id="deleteConfirmationFormItemDeleting"></p>
                                    <p>Type <kbd>delete</kbd> to confirm deletion.</p>
                                    <input type="text" className="form-control" name="delete" minLength={6} maxLength={6} placeholder="type 'delete'" onChange={e => {
                                        const deleteBtn = document.getElementById("deleteConfirmationFormDeleteBtn");
                                        if (e.target.value === "delete") {
                                            this.setState({deleteBtnDisabled:false});
                                        } else {
                                            this.setState({deleteBtnDisabled:true});
                                        }
                                    }} required />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="deleteConfirmationFormBackBtn" className="btn btn-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" id="deleteConfirmationFormDeleteBtn" className="btn btn-danger" onClick={() => this.confirmDeleteCategory()} disabled={this.state.deleteBtnDisabled}>Delete</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="createCategoryModal" tabIndex="-1" aria-labelledby="createCategoryModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createCategoryModalLabel">{this.state.editingCategory?"Edit":"Create"} Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({editingCategory:undefined})}></button>
                        </div>
                        <div className="modal-body">
                            <form id="createCategoryForm">
                                <div className="form-group">
                                    <label htmlFor="name" className="fw-bold">Name</label>
                                    <input type="text" className="form-control" name="name" minLength={1} maxLength={50} defaultValue={this.state.editingCategory?this.state.editingCategory.name:""} placeholder="e.g. Bread" required />
                                    <div className="invalid-feedback">Category name must be between 1 to 50 characters</div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <this.DeleteCategoryBtn />
                            <button type="button" id="createCategoryFormBackBtn" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.setState({editingCategory:undefined})}>Cancel</button>
                            <button type="button" id="createCategoryFormCreateBtn" className="btn btn-primary" onClick={() => {
                                this.state.editingCategory?this.createCategory():this.createCategory();
                            }}>{this.state.editingCategory?"Edit":"Create"}</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Menu</h1>
                    </div>
                    <span className="text-muted">{this.state.shop.displayName}</span>
                    <hr />
                    <button type="button" className="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#createCategoryModal">Add Category</button>
                    {this.state.loading ? 
                        <div className="d-flex">
                            <div className="spinner-border" role="status"></div>
                            <h2 className="ms-3">Loading</h2>
                        </div> : <></>}
                    <div className="accordion" id="productCategoriesAccordion">
                        {this.state.productCategories.map((item, idx) => {
                            return (
                                <div className="accordion-item" key={idx}>
                                    <h2 className="accordion-header" id={`productCategory_${idx}`}>
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#productCategoryCollapse_${idx}`} aria-expanded="true" aria-controls={`productCategoryCollapse_${idx}`}>
                                            {item.name}
                                        </button>
                                    </h2>
                                    <div id={`productCategoryCollapse_${idx}`} className="accordion-collapse collapse" aria-labelledby={`productCategory_${idx}`} data-bs-parent="#productCategoriesAccordion">
                                        <div className="accordion-body">
                                            <div>
                                                <button type="button" className="btn btn-primary" onClick={() => this.props.navigate(`product/add`)}>Add Product</button>
                                                <button type="button" className="btn btn-outline-primary ms-3" data-bs-toggle="modal" data-bs-target="#createCategoryModal" onClick={() => this.setState({editingCategory:item})}>Edit Category</button>
                                            </div>
                                            <br />
                                            <div className="d-flex flex-wrap justify-content-between">
                                                {item.products ? item.products.map((item, idx) => {
                                                    return (
                                                        <div className="card mb-3" style={{width:"48%"}} key={idx} onClick={() => this.props.navigate(`product/${item._id}`, {state:item})}>
                                                            <img className="card-img-top" src={`${constants.PRODUCT_MEDIA_BASE_URL}/${item.options[0].media.filename}`} />
                                                            <div className="card-body">
                                                                <h5 className="card-title">{item.name}</h5>
                                                                <hr />
                                                                <div className="d-flex flex-column">
                                                                    <h6 className="card-title">Stock</h6>
                                                                    {item.options.map((item, idx) => {
                                                                        return (
                                                                            <span className="card-text" key={idx}>{`${item.name}: ${item.quantity}`}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let { id } = useParams();
    return <ManageMenu {...props} navigate={navigate} id={id} />
}

export default WithNavigate; 