import React from 'react';
import { faChevronLeft, faImage, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import constants from '../constants';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class AddProduct extends React.Component {
    constructor() {
        super();
        this.state = {
            shop: {displayName:""},
            loadingCategory: true,
            errors: [],
            categories: [],
            addOns: [],
            options: [{name:undefined,costPrice:undefined,listPrice:undefined,quantity:undefined,image:undefined}],
            optionCropping: 0,
            cropperImg: "",
            success: false
        };
        this.croppedImage = "";
    };

    componentDidMount() {
        const initData = "d" //window.Telegram.WebApp.initData;
        this.getCategories();
    };

    getCategories() {
        this.setState({loadingCategory:true});
        fetch(`${constants.API_BASE_URL}/merchant/product/category/retrieveAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69", //window.Telegram.WebApp.initData,
                shop: this.props.id
            })
        }).then(async res => await res.json())
        .then(data => {
            if (data.ok) {
                this.setState({shop:data.data.shop,categories:data.data.productCategories,loadingCategory:false});
            } else {
                this.setState({formErrors:typeof data.err === 'object'?[...data.err]:[data.err],loadingCategory:false});
            };
        })
        .catch(err => {
            this.setState({formErrors:err?[err.message]:'• An unexpected error occurred, please try again',loadingCategory:false});
        });
    };

    createCategory() {
        const createBtn = document.querySelector('#createCategoryFormCreateBtn');
        const form = document.querySelector('#createCategoryForm');
        const formData = new FormData(form);
        var object = {
            initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69", //window.Telegram.WebApp.initData, 
            shop: this.props.id
        };
        formData.forEach((value, key) => object[key] = value);
        var json = JSON.stringify(object);
        if (form.checkValidity()) {
            createBtn.setAttribute("disabled", true);
            createBtn.innerHTML = `Creating...`;
            fetch(`${constants.API_BASE_URL}/merchant/product/category/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: json
            }).then(async res => await res.json())
            .then(data => {
                console.log(data)
                createBtn.removeAttribute("disabled");
                createBtn.innerHTML = `Create`;
                if (data.ok) {
                    this.getCategories();
                    document.getElementById("createCategoryFormBackBtn").click();
                } else {
                    this.setState({formErrors:typeof data.err === 'object'?[...data.err]:[data.err]});
                };
            })
            .catch(err => {
                console.log(err)
                createBtn.removeAttribute("disabled");
                createBtn.innerHTML = `Create`;
                this.setState({formErrors:err?[err.message]:'• An unexpected error occurred, please try again'});
            });
        } else {
            form.classList.add("was-validated");
        };
    };

    selectCategory() {
        const category = document.querySelector("#selectCategoryForm input[name='category']:checked");
        if (category) {
            const catLabel = document.getElementById(`catLabel_${category.value}`).innerText;
            document.querySelector("#category").innerHTML = `<option value="${category.value}" selected>${catLabel}</option>`;
            document.getElementById("selectCategoryFormCancelBtn").click();
            document.querySelector("#selectCategoryErr").classList.add("d-none");
        } else {
            document.querySelector("#selectCategoryErr").classList.remove("d-none");
        };
    };

    showCategories() {
        if (this.state.loadingCategory) {
            return (<div className="spinner-border" role="status" />);
        } else if (this.state.categories.length > 0) {
            return this.state.categories.map((item, idx) => {
                return (
                    <li className="list-group-item" key={idx} onClick={() => document.getElementById(`cat_${item._id}`).checked = true}>
                        <input type="radio" name="category" value={item._id} id={`cat_${item._id}`} />
                        <label htmlFor={`cat_${item._id}`} id={`catLabel_${item._id}`} className="ms-3">{item.name}</label>
                    </li>
                );
            });
        } else {
            <span>It's empty in here, let's create a category!</span>
        };
    };

    showOptions() {
        const examples = ["Original", "Chocolate", "Cream", "Peanut Butter", "Matcha"]
        return this.state.options.map((item, idx) => {
            return (
                <li className="list-group-item d-flex flex-column" key={idx}>
                    <div className={`text-danger ms-2 ms-auto ${idx===0?'d-none':''}`} onClick={() => {
                        const confirmDelete = window.confirm("Tap 'OK' to delete option");
                        if (confirmDelete) {
                            const temp = this.state.options;
                            temp.splice(idx, 1);
                            this.setState({options:temp});
                        }
                    }}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span className="ms-2">Remove Option</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <div className="form-group">
                                <label htmlFor={`optionName_${idx}`} className="fw-bold">Name</label>
                                <input type="text" className="form-control me-3" id={`optionName_${idx}`} name={`optionName_${idx}`} minLength={1} maxLength={20} placeholder={`e.g. ${examples[idx]}`} value={this.state.options.length===1?'Default':item.name} onChange={e => {
                                    var temp = this.state.options;
                                    temp[idx].name = e.target.value;
                                    this.setState({options:temp});
                                }} disabled={this.state.options.length===1} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`optionCostPrice_${idx}`} className="fw-bold">Cost Price</label>
                                <input type="number" className="form-control me-3" name={`optionCostPrice_${idx}`} min={0.01} max={999.99} step={0.01} placeholder="e.g. 4.40" value={item.costPrice} onChange={e => {
                                    var temp = this.state.options;
                                    temp[idx].costPrice = e.target.value;
                                    this.setState({options:temp});
                                }} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`optionListPrice_${idx}`} className="fw-bold">List Price</label>
                                <input type="number" className="form-control me-3" name={`optionListPrice_${idx}`} min={0.01} max={999.99} step={0.01} placeholder="e.g. 3.90" value={item.listPrice} onChange={e => {
                                    var temp = this.state.options;
                                    temp[idx].listPrice = e.target.value;
                                    this.setState({options:temp});
                                }} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`optionQuantity_${idx}`} className="fw-bold">Stock Quantity</label>
                                <input type="number" className="form-control me-3" name={`optionQuantity_${idx}`} min={0} max={9999} placeholder="e.g. 50" value={item.quantity} onChange={e => {
                                    var temp = this.state.options;
                                    temp[idx].quantity = e.target.value;
                                    this.setState({options:temp});
                                }} required />
                            </div>
                        </div>
                        <div className="has-validation d-flex flex-column">
                        <div onClick={() => document.getElementById(`optionImage_${idx}`).click()}>{this.state.options[idx].image ? <img src={URL.createObjectURL(this.state.options[idx].image)} className="ms-auto" width="100px" height="100px" /> : <div className="bg-light d-flex flex-column justify-content-center align-items-center ms-auto" style={{width:'100px',height:'100px'}}><FontAwesomeIcon icon={faImage} className="fs-3 mb-1" /><span className="text-center">Upload Image</span></div>}</div>
                            <input type="file" className="d-none" id={`optionImage_${idx}`} name={`optionImage_${idx}`} accept="image/png, image/jpeg" required={idx===0} onChange={e => {
                                const file = e.target.files[0];
                                if (file) {
                                    document.getElementById("initCropperBtn").click();
                                    setTimeout(() => this.setState({cropperImg:URL.createObjectURL(file),optionCropping:idx}), 500)
                                }
                            }}></input>
                            <span className="invalid-feedback" style={{width:"100px"}}>Please upload an image.</span>
                        </div>
                    </div>
                </li>
            )
        });
    };

    submitForm() {
        const addProductForm = document.getElementById("addProductForm");
        if (addProductForm.checkValidity()) {
            var formData = new FormData();
            const name = document.getElementById("name").value;
            const description = document.getElementById("description").value;
            const category = document.getElementById("category").value;
            const reserve = document.getElementById("reserve").checked;
            formData.append("initData", "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69");
            formData.append("shop", this.props.id);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("category", category);
            formData.append("reserve", reserve);
            this.state.options.forEach(item => {
                formData.append("optionName", item.name);
                formData.append("optionCostPrice", item.costPrice);
                formData.append("optionListPrice", item.listPrice);
                formData.append("optionQuantity", item.quantity);
                formData.append("optionImage", item.image);
            });
            fetch(`${constants.API_BASE_URL}/merchant/product/add`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: formData
            }).then(async res => await res.json())
            .then(res => {
                if (res.ok) {
                    this.setState({success:true});
                } else {
                    
                }
            })
            .catch(err => console.error(err))
        } else {
            addProductForm.classList.add("was-validated");
        };
    };

    render() {
        const onCrop = (e) => {
            const imageElement = e?.currentTarget;
            const cropper = imageElement?.cropper;
            cropper.getCroppedCanvas().toBlob(blob => {
                this.croppedImage = blob;
            })
        };

        if (this.state.success) {
            return (
                <div className="vh-100 d-flex flex-column justify-content-center align-items-center container">
                    <h1>Product Created</h1>
                    <span className="text-muted text-center">Your product has been created successfully!</span>
                        <button type="button" className="btn btn-outline-primary w-75 mt-4" onClick={() => window.location.reload()}>Add Another Product</button>
                        <button type="button" className="btn btn-primary w-75 mt-4" onClick={() => this.props.navigate(-1)}>Manage Menu</button>
                </div>
            )
        } else {
            return (
                <div className="vh-100">
                    <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                    <div className="modal fade" id="cropperModal" tabIndex="-1" aria-labelledby="cropperModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="cropperModalLabel">Crop Image</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({cropperImg:""})}></button>
                            </div>
                            <div className="modal-body">
                                {this.state.cropperImg ? <Cropper
                                    src={this.state.cropperImg}
                                    viewMode={2}
                                    autoCropArea={1}
                                    center={true}
                                    // Cropper.js options
                                    aspectRatio={1/1}
                                    guides={false}
                                    crop={onCrop}
                                /> : <div className="spinner-border" role="status" />}
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="cropperFormCancelBtn" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.setState({cropperImg:""})}>Cancel</button>
                                <button type="button" id="cropperFormCreateBtn" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
                                    const index = this.state.optionCropping;
                                    const temp = this.state.options;
                                    temp[index].image = new File([this.croppedImage], index);
                                    this.setState({cropperImg:""})}
                                }>Crop</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="createCategoryModal" tabIndex="-1" aria-labelledby="createCategoryModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="createCategoryModalLabel">Create Category</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id="createCategoryForm">
                                    <div className="form-group">
                                        <label htmlFor="name" className="fw-bold">Name</label>
                                        <input type="text" className="form-control" name="name" minLength={1} maxLength={50} placeholder="e.g. Bread" required />
                                        <div className="invalid-feedback">Category name must be between 1 to 50 characters</div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="createCategoryFormBackBtn" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#categoryModal">Back</button>
                                <button type="button" id="createCategoryFormCreateBtn" className="btn btn-primary" onClick={() => this.createCategory()}>Create</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="categoryModal" tabIndex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="categoryModalLabel">Select Category</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id="selectCategoryForm">
                                    <ul className="list-group mb-3">
                                        {this.showCategories()}
                                    </ul>
                                    <span className="text-danger d-none" id="selectCategoryErr">Please select a category</span>
                                </form>
                                <button type="button" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#createCategoryModal">Create Category</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="selectCategoryFormCancelBtn" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.selectCategory()}>Select</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="createAddOnsModal" tabIndex="-1" aria-labelledby="createAddOnsModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="createAddOnsModalLabel">Create Add-ons</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id="createAddOnsForm">
                                    <div className="form-group">
                                        <label htmlFor="name" className="fw-bold">Name</label>
                                        <input type="text" className="form-control" name="name" minLength={1} maxLength={50} placeholder="e.g. Soybean Milk" required />
                                        <div className="invalid-feedback">Add-on name must be between 1 to 50 characters</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="fw-bold">Price</label>
                                        <input type="text" className="form-control" name="name" min={0} max={999.99} step={0.01} placeholder="e.g. 1.90" required />
                                        <div className="invalid-feedback">Price must be between 0 to 999.99</div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name" className="fw-bold">Stock Quantity</label>
                                        <input type="text" className="form-control" name="name" min={0} max={9999} placeholder="e.g. 50" required />
                                        <div className="invalid-feedback">Stock quantity must be between 0 to 9999</div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="createAddOnsFormBackBtn" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#AddOnsModal">Back</button>
                                <button type="button" id="createAddOnsFormCreateBtn" className="btn btn-primary" onClick={() => {}}>Create</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="addOnsModal" tabIndex="-1" aria-labelledby="addOnsModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addOnsModalLabel">Select Add-ons</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id="selectaddOnsForm">
                                    <ul className="list-group mb-3">
                                        
                                    </ul>
                                    <span className="text-danger d-none" id="selectaddOnsErr">Please select add-ons</span>
                                </form>
                                <button type="button" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#createaddOnsModal">Create Add-ons</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="selectaddOnsFormCancelBtn" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={() => {}}>Select</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="container my-3">
                        <div className="d-flex align-items-center mb-3">
                            <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                            <h1 className="ms-3 m-0">Add Product</h1>
                        </div>
                        <span className="text-muted">{this.state.shop.displayName}</span>
                        <hr />
                        <form id="addProductForm">
                            <div className="form-group mt-2">
                                <label htmlFor="name" className="fw-bold">Name</label>
                                <input type="text" className="form-control" name="name" id="name" minLength={1} maxLength={50} placeholder="e.g. Croissant" required />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="description" className="fw-bold">Description</label>
                                <textarea type="text" className="form-control" name="description" id="description" minLength={1} maxLength={300} rows={3} placeholder="e.g. Croissant is a buttery and flaky puff pastry." required />
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="category" className="fw-bold">Category</label>
                                <select className="form-control" name="category" id="category" data-bs-toggle="modal" data-bs-target="#categoryModal" defaultValue='' onMouseDown={e => {
                                    e.preventDefault();
                                }} required>
                                    <option disabled value=''>- Select Category -</option>
                                </select>
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="category" className="fw-bold">Reserve Product</label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="reserve" name="reserve" />
                                    <label className="form-check-label" for="reserve">Allow customers to reserve product when stock is empty</label>
                                </div>
                            </div>
                            <div className="form-group mt-2">
                                <label htmlFor="options" className="fw-bold">Options</label>
                                <ul className="list-group mb-3">
                                    {this.showOptions()}
                                </ul>
                                <button type="button" className={`btn btn-outline-primary ${this.state.options.length===5?'d-none':''}`} onClick={() => {
                                    const temp = this.state.options;
                                    const defaultOption = document.getElementById('optionName_0');
                                    if (defaultOption.value === "Default") defaultOption.value = '';
                                    temp.push({name:undefined,costPrice:undefined,listPrice:undefined,quantity:undefined,image:undefined});
                                    this.setState({options:temp});
                                }}>Add Option</button>
                            </div>
                            {/* <div className="form-group mt-2">
                                <label htmlFor="addOns" className="fw-bold">Add-ons</label>
                                <div id="addOns">
                                    <span>No Add-ons selected</span>
                                </div>
                                <button type="button" className="btn btn-outline-primary mt-3" data-bs-toggle="modal" data-bs-target="#addOnsModal">Configure Add-ons</button>
                            </div> */}
                            <button type="button" id="initCropperBtn" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#cropperModal">Init Cropper</button>
                            <button type="button" className="btn btn-primary w-100 my-3" onClick={() => {
                                var object = {};
                                const formData = new FormData(document.getElementById("addProductForm"));
                                formData.forEach((value, key) => object[key] = value);
                                var json = JSON.stringify(object);
                                console.log(object)
                                this.submitForm();
                            }}>Add Product</button>
                        </form>
                    </div>
                </div>
            );
        };
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let { id } = useParams();
    return <AddProduct {...props} navigate={navigate} id={id} />
}

export default WithNavigate; 