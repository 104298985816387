import React from "react";
import Stepper from 'bs-stepper';
import constants from "../constants";
import { useNavigate } from "react-router-dom";

class CreateShop extends React.Component {
    constructor() {
        super();
        this.state = {
            success: false,
            basicInformation: {licenceNo:'',licenseeName:'',address:{postalCode:'',street:'',unitNo:'',coords:[]},phoneNo:''},
            operatingHours: [{},{},{},{},{},{},{}],
            accountInformation: {displayName:'',username:''},
            formErrors: []
        };
        this.weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    };

    componentDidMount() {
        if (!this.state.success) {
            this.stepper = new Stepper(document.querySelector('.bs-stepper'), {linear: false});
            const initData = window.Telegram.WebApp.initData;
            if (!initData) {
                // document.querySelector('#sessionExpired').classList.remove('d-none');
                // document.querySelector('.container').classList.add('d-none');
            }
        }
    };

    propagateHours(e) {
        var openingHours = [];
        var closingHours = [];
        for (const i in this.state.operatingHours) {
            const opening = this.state.operatingHours[i].opening;
            const closing = this.state.operatingHours[i].closing;
            if (opening) {
                openingHours.push(opening);
            }
            if (closing) {
                closingHours.push(closing);
            }
        }
        if (openingHours.length === 1 && parseInt(openingHours[0]) >= 0 && parseInt(openingHours[0]) < 2400) {
            var temp = this.state.operatingHours;
            temp.forEach(i => i.opening = openingHours[0]);
            this.setState({operatingHours:[...this.state.operatingHours]});
        }
        if (closingHours.length === 1 && parseInt(openingHours[0]) >= 0 && parseInt(openingHours[0]) < 2400) {
            temp = this.state.operatingHours;
            temp.forEach(i => i.closing = closingHours[0]);
            this.setState({operatingHours:[...this.state.operatingHours]});
        }
    };

    getLicensee() {
        const licenceNo = this.state.basicInformation.licenceNo;
        if (licenceNo) {
            fetch(`${constants.API_BASE_URL}/merchant/shop/getLicensee/${licenceNo}`)
            .then(async res => {
                const json = await res.json();
                if (json.ok) {
                    const licensee = json.licensee;
                    var basicInformation = this.state.basicInformation;
                    var accountInformation = this.state.accountInformation;
                    basicInformation.licenseeName = licensee.licenseeName;
                    basicInformation.address.postalCode = licensee.postalCode;
                    basicInformation.address.street = licensee.street;
                    basicInformation.address.unitNo = licensee.unitNo;
                    accountInformation.displayName = licensee.businessName;
                    this.setState({
                        basicInformation,
                        accountInformation
                    });
                    this.setCoords(licensee.postalCode);
                };
            })
            .catch(err => console.log(err));
        };
    };

    setCoords(postalCode) {
        console.log('setCoordsCalled')
        console.log(postalCode.length)
        if (postalCode.length === 6) {
            fetch(`https://developers.onemap.sg/commonapi/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=N&pageNum=1`)
            .then(async res => res.json())
            .then(data => {
                if (data.found) {
                    var temp = this.state.basicInformation;
                    temp.address.coords.push(parseFloat(data.results[0].LONGITUDE));
                    temp.address.coords.push(parseFloat(data.results[0].LATITUDE));
                    this.setState({basicInformation:{...temp}})
                }
            });
        }
    }

    submitForm() {
        var formErrors = [];
        const basicInformation = this.state.basicInformation;
        const operatingHours = this.state.operatingHours;
        const accountInformation = this.state.accountInformation;

        for (const item in basicInformation) {
            const splitKey = item.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
            const formattedKey = splitKey.charAt(0).toUpperCase() + splitKey.slice(1);
            if (item !== 'address' && !basicInformation[item]) {
                formErrors.push(`• ${item === 'licenseeName'?'Licensee ':''}${formattedKey} is required`);
            } else if (item === 'address') {
                const postalCode = basicInformation[item].postalCode;
                const street = basicInformation[item].street;
                if (postalCode.length !== 6 || isNaN(parseInt(postalCode))) {
                    formErrors.push(`• Postal Code must be 6 digits`);
                };
                if (!street) {
                    formErrors.push(`• Street is required`);
                };
            } else if (item === 'phoneNo') {
                const phoneNo = basicInformation[item];
                if (phoneNo.length !== 8 || isNaN(parseInt(phoneNo))) {
                    formErrors.push(`• ${formattedKey} must be 8 digits`);
                };
            };
        };
        var daysWithErrors = [];
        for (const item in operatingHours) {
            const hours = operatingHours[item];
            if ((!hours['opening'] && hours['closing']) || (hours['opening'] && !hours['closing'])) {
                daysWithErrors.push(this.weekdays[item]);
            }
        }
        if (daysWithErrors.length !== 0) {
            formErrors.push(`• Both opening and closing times must be filled for ${daysWithErrors.join(', ')}`);
        };
        for (var item in accountInformation) {
            const splitKey = item.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
            const formattedKey = splitKey.charAt(0).toUpperCase() + splitKey.slice(1);
            if (item === 'displayName') {
                const displayName = accountInformation[item];
                if (displayName.length < 1 || displayName.length > 50) {
                    formErrors.push(`• ${formattedKey} must between 1 to 50 characters long`);
                };
            } 
            if (item === 'username') {
                const username = accountInformation[item];
                if (username.length < 5 || username.length > 20) {
                    formErrors.push(`• ${formattedKey} must between 5 to 20 characters long`);
                };
            };
        };

        if (formErrors.length) {
            this.setState({formErrors});
        } else {
            fetch(`${constants.API_BASE_URL}/merchant/shop/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    initData: window.Telegram.WebApp.initData,
                    basicInformation:this.state.basicInformation,
                    operatingHours:this.state.operatingHours,
                    accountInformation:this.state.accountInformation
                })
            }).then(async res => await res.json())
            .then(data => {
                if (data.ok) {
                    this.setState({success:true});
                } else {
                    this.setState({formErrors:typeof data.err === 'object'?[...data.err]:[data.err]});
                };
            })
            .catch(err => {
                this.setState({formErrors:err?[err.message]:'• An unexpected error occurred, please try again'});
            });
        };
    };

    render() {
        if (this.state.success) {
            return (
                <div className="vh-100 d-flex flex-column justify-content-center align-items-center container">
                    <h1>Shop Created</h1>
                    <span className="text-muted text-center">We are reviewing your application. In the meanwhile, you may manage your shop's menu!</span>
                    <button type="button" className="btn btn-primary w-75 mt-4" onClick={() => this.props.navigate("/merchant/shop/select?redirect=menu")}>Manage Menu</button>
                </div>
            )
        } else {
            return (
                <div>
                    <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                    <div className="container">
                        <form id="createShopForm" className="my-3">
                            <h1 className="">Create a Shop</h1>
                            <div id="formError" className="alert alert-warning fade show" role="alert" style={this.state.formErrors.length!==0?{display:'block'}:{display:'none'}}>
                                {this.state.formErrors.map((err,idx) => <div key={idx}><span>{err}</span><br/></div>)}
                            </div>
                            <div className="bs-stepper">
                                <div className="bs-stepper-header d-flex flex-wrap" role="tablist">
                                    <div className="step" data-target="#stepper-basic">
                                        <button type="button" className="step-trigger" role="tab" aria-controls="stepper-basic" id="stepper-basic-trigger">
                                            <span className="bs-stepper-circle">1</span>
                                            <span className="bs-stepper-label">Basic Information</span>
                                        </button>
                                    </div>
                                    <div className="step" data-target="#stepper-operatingHours">
                                        <button type="button" className="step-trigger" role="tab" aria-controls="stepper-operatingHours" id="stepper-operatingHours-trigger">
                                            <span className="bs-stepper-circle">2</span>
                                            <span className="bs-stepper-label">Operating Hours</span>
                                        </button>
                                    </div>
                                    <div className="step" data-target="#stepper-accountInformation">
                                        <button type="button" className="step-trigger" role="tab" aria-controls="stepper-accountInformation" id="stepper-accountInformation-trigger">
                                            <span className="bs-stepper-circle">3</span>
                                            <span className="bs-stepper-label">Account Information</span>
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div className="bs-stepper-content mt-2">
                                    <div id="stepper-basic" className="content" role="tabpanel" aria-labelledby="stepper-basic-trigger">
                                        <div className="form-group">
                                            <label htmlFor="licenceNo">SFA Licence No.</label>
                                            <input type="text" className="form-control" name="licenceNo" placeholder="e.g. B98765V012" value={this.state.basicInformation.licenceNo} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.licenceNo = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                            }} onBlur={() => this.getLicensee()} required />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="licenseeName">Licensee Name</label>
                                            <input type="text" className="form-control" name="licenseeName" placeholder="e.g. Jamie's Bread and Cake Shop Pte. Ltd." value={this.state.basicInformation.licenseeName} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.licenseeName = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                            }} required />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="postalCode">Postal Code</label>
                                            <input type="text" className="form-control" name="postalCode" minLength={6} maxLength={6} placeholder="e.g. 768019" value={this.state.basicInformation.address.postalCode} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.address.postalCode = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                                this.setCoords(e.target.value);
                                            }} required />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="street">Street</label>
                                            <input type="text" className="form-control" name="street" placeholder="e.g. 1 Northpoint Drive" value={this.state.basicInformation.address.street} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.address.street = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                            }} required />
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="unitNo">Unit No. (if applicable)</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="unitNoPrepend">#</span>
                                                </div>
                                                <input type="text" className="form-control" name="unitNo" placeholder="e.g. 01-02" aria-describedby="unitNoPrepend" value={this.state.basicInformation.address.unitNo} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.address.unitNo = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                            }} />
                                            </div>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="phoneNo">Phone No.</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="phoneNoPrepend">+65</span>
                                                </div>
                                                <input type="text" className="form-control" name="phoneNo" minLength={8} maxLength={8} placeholder="e.g. 67654321" aria-describedby="phoneNoPrepend" value={this.state.basicInformation.phoneNo} onChange={e => {
                                                var temp = this.state.basicInformation;
                                                temp.phoneNo = e.target.value;
                                                this.setState({basicInformation:{...temp}})
                                            }} required />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <button type="button" className="btn btn-primary" onClick={() => this.stepper.next()}>Next</button>
                                        </div>
                                    </div>
                                    <div id="stepper-operatingHours" className="content" role="tabpanel" aria-labelledby="stepper-operatingHours-trigger">
                                        <div className="alert alert-info" role="alert">Leave opening and closing times blank for non-operating days</div>
                                        {this.weekdays.map((day, idx) => {
                                            return (
                                                <div className={`form-group ${idx>0?'mt-2':''}`} key={idx}>
                                                    <label htmlFor={`hours${day}`}>{day}</label>
                                                    <div id={`hours${day}`}>
                                                        <div className="input-group mt-2">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id={`opening${day}Prepend`}>Opening</span>
                                                            </div>
                                                            <input type="text" className={`form-control operatingHours opening${day}`} placeholder="e.g. 0900" aria-describedby={`opening${day}Prepend`} value={this.state.operatingHours[idx].opening?this.state.operatingHours[idx].opening:''} onChange={e => {
                                                                var temp = this.state.operatingHours;
                                                                temp[idx].opening = e.target.value;
                                                                this.setState({operatingHours:[...temp]});
                                                            }} onBlur={e => this.propagateHours(e)} />
                                                        </div>
                                                        <div className="input-group mt-2">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id={`closing${day}Prepend`}>Closing</span>
                                                            </div>
                                                            <input type="text" className={`form-control closingHours closing${day}`} placeholder="e.g. 2100" aria-describedby={`closing${day}Prepend`} value={this.state.operatingHours[idx].closing?this.state.operatingHours[idx].closing:''} onChange={e => {
                                                                var temp = this.state.operatingHours;
                                                                temp[idx].closing = e.target.value;
                                                                this.setState({operatingHours:[...temp]});
                                                            }} onBlur={e => this.propagateHours(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="mt-4">
                                            <button type="button" className="btn btn-light" onClick={() => this.stepper.previous()}>Back</button>
                                            <button type="button" className="btn btn-primary ms-3" onClick={() => this.stepper.next()}>Next</button>
                                        </div>
                                    </div>
                                    <div id="stepper-accountInformation" className="content" role="tabpanel" aria-labelledby="stepper-accountInformation-trigger">
                                        <div className="form-group mt-2">
                                            <label htmlFor="displayName">Display Name</label>
                                            <input type="text" className="form-control" name="displayName" minLength={1} maxLength={50} placeholder="e.g. Jamie's Bakery (Northpoint City)" value={this.state.accountInformation.displayName} onChange={e => {
                                                var temp = this.state.accountInformation;
                                                temp.displayName = e.target.value;
                                                this.setState({accountInformation:{...temp}})
                                            }} required />
                                            <small id="displayNameHint" className="form-text text-muted">This name will appear on your shop and product listings</small>
                                        </div>
                                        <div className="form-group mt-2">
                                            <label htmlFor="username">Username</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="usernamePrepend">@</span>
                                                </div>
                                                <input type="text" className="form-control" name="username" minLength={5} maxLength={20} placeholder="e.g. jamiesbakery" aria-describedby="usernamePrepend" value={this.state.accountInformation.username} onChange={e => {
                                                var temp = this.state.accountInformation;
                                                temp.username = e.target.value;
                                                this.setState({accountInformation:{...temp}})
                                            }} />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <button type="button" className="btn btn-light" onClick={() => this.stepper.previous()}>Back</button>
                                            <button type="button" className="btn btn-primary ms-3" onClick={() => this.submitForm()}>Create Shop</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }
    }
};

function WithNavigate(props) {
    let navigate = useNavigate();
    return <CreateShop {...props} navigate={navigate} />
}

export default WithNavigate;