import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import constants from '../constants';

class ManageOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shop: props.shop,
            orders: {}
        };
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        this.retrieveOrders();
    };

    sortOrders(unsorted) {
        var unfufilled = [];
        var toBeCollected = [];
        var collected = [];
        var cancelled = [];
        for (const i in unsorted) {
            if (unsorted[i].status === "PAID") {
                unfufilled.push(unsorted[i]);
            } else if (unsorted[i].status === "TO_COLLECT") {
                toBeCollected.push(unsorted[i]);
            } else if (unsorted[i].status === "COLLECTED") {
                collected.push(unsorted[i]);
            } else if (unsorted[i].status === "CANCELLED" || unsorted[i].status === "REFUNDED") {
                cancelled.push(unsorted[i]);
            };
        }
        this.setState({orders: {unfufilled, toBeCollected, collected, cancelled}});
    };

    retrieveOrders() {
        fetch(`${constants.API_BASE_URL}/merchant/order/retrieveAll`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69",
                shop: this.props.shop._id,
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.sortOrders(res.data.orders);
            } else {

            };
        })
        .catch(err => {

        });
    };

    buttonAction(endpoint, order) {
        fetch(`${constants.API_BASE_URL}/merchant/order/action/${endpoint}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData: "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69",
                shop: this.state.shop._id,
                order: order
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.retrieveOrders();
            } else {

            };
        })
        .catch(err => {

        });
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faChevronLeft} className="fs-3 text-primary" onClick={() => this.props.navigate(-1)} />
                        <h1 className="ms-3 m-0">Orders</h1>
                    </div>
                    <span className="text-muted">{this.state.shop.displayName}</span>
                    <hr />
                    <div>
                        <nav className="nav nav-pills">
                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#unfufilledTab" type="button" role="tab" aria-controls="unfufilled" aria-selected="true">Unfufilled</button>
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#toBeCollectedTab" type="button" role="tab" aria-controls="toBeCollected" aria-selected="false">To Be Collected</button>
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#completedTab" type="button" role="tab" aria-controls="collected" aria-selected="false">Collected</button>
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#cancelledTab" type="button" role="tab" aria-controls="cancelled" aria-selected="false">Cancelled</button>
                        </nav>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="unfufilledTab" role="tabpanel" aria-labelledby="unfufilledTab">
                                {this.state.orders.unfufilled ? this.state.orders.unfufilled.map((order, idx) => {
                                    return (
                                        <div className="d-flex flex-column py-3 border-bottom" key={idx}>
                                             <div className="d-flex flex-column">
                                                <div>
                                                    <span className="text-muted">{order._id.slice(0, -6)}</span>
                                                    <strong className="fs-5">{order._id.slice(-6)}</strong>
                                                </div>
                                                <span>Ordered: {new Date(order.orderDate).toLocaleString('en-SG', {timeZone:'Asia/Singapore'})}</span>
                                             </div>
                                             <div className="mt-2 d-flex flex-column">
                                                { order.products.map((product, idx2) => {
                                                    var currentOption = {media:{}};
                                                    product.product.options.forEach(option => {
                                                        if (option._id === product.option) {
                                                            currentOption = option;
                                                        };
                                                    });
                                                    return (
                                                        <span key={idx2}>{product.quantity}x {product.product.name} ({currentOption.name})</span>
                                                    )
                                                }) }
                                             </div>
                                             <div className="mt-3 d-flex">
                                                <button className="btn btn-danger" onClick={() => this.buttonAction("reject", order._id)}>Reject</button>
                                                <button className="btn btn-success ms-3" onClick={() => this.buttonAction("accept", order._id)}>Accept & Prepare</button>
                                                <strong className={`ms-auto text-center ${order.byo ? '' : 'hidden'}`}>Bringing<br/>Container</strong>
                                            </div>
                                        </div>
                                    )
                                }) : <div className="d-flex"><div className="spinner-border" role="status"></div><h2 className="ms-3">Loading</h2></div> }
                            </div>
                            <div className="tab-pane fade show" id="toBeCollectedTab" role="tabpanel" aria-labelledby="toBeCollectedTab">
                                {this.state.orders.toBeCollected ? this.state.orders.toBeCollected.map((order, idx) => {
                                    const disabledNoShow = (Date.now() - order.acceptedDate) > 14400;
                                    return (
                                        <div className="d-flex flex-column py-3 border-bottom" key={idx}>
                                             <div className="d-flex flex-column">
                                                <div>
                                                    <span className="text-muted">{order._id.slice(0, -6)}</span>
                                                    <strong className="fs-5">{order._id.slice(-6)}</strong>
                                                </div>
                                                <span>Accepted: {new Date(order.acceptedDate).toLocaleString('en-SG', {timeZone:'Asia/Singapore'})}</span>
                                             </div>
                                             <div className="mt-2 d-flex flex-column">
                                                { order.products.map((product, idx2) => {
                                                    var currentOption = {media:{}};
                                                    product.product.options.forEach(option => {
                                                        if (option._id === product.option) {
                                                            currentOption = option;
                                                        };
                                                    });
                                                    return (
                                                        <span key={idx2}>{product.quantity}x {product.product.name} ({currentOption.name})</span>
                                                    )
                                                }) }
                                             </div>
                                             <div className="mt-3 d-flex">
                                                <button className="btn btn-danger" onClick={() => this.buttonAction("noShow", order._id)} disabled={disabledNoShow}>No Show</button>
                                                <button className="btn btn-success ms-3" onClick={() => this.buttonAction("collected", order._id)}>Collected</button>
                                                <strong className={`ms-auto text-center ${order.byo ? '' : 'hidden'}`}>Bringing<br/>Container</strong>
                                            </div>
                                        </div>
                                    )
                                }) : <div className="d-flex"><div className="spinner-border" role="status"></div><h2 className="ms-3">Loading</h2></div> }
                            </div>
                            <div className="tab-pane fade show" id="completedTab" role="tabpanel" aria-labelledby="completedTab">
                                {this.state.orders.collected ? this.state.orders.collected.map((order, idx) => {
                                    return (
                                        <div className="d-flex flex-column py-3 border-bottom" key={idx}>
                                             <div className="d-flex flex-column">
                                                <div>
                                                    <span className="text-muted">{order._id.slice(0, -6)}</span>
                                                    <strong className="fs-5">{order._id.slice(-6)}</strong>
                                                </div>
                                                <span>Collected: {new Date(order.collectedDate).toLocaleString('en-SG', {timeZone:'Asia/Singapore'})}</span>
                                             </div>
                                             <div className="mt-2 d-flex flex-column">
                                                { order.products.map((product, idx2) => {
                                                    var currentOption = {media:{}};
                                                    product.product.options.forEach(option => {
                                                        if (option._id === product.option) {
                                                            currentOption = option;
                                                        };
                                                    });
                                                    return (
                                                        <span key={idx2}>{product.quantity}x {product.product.name} ({currentOption.name})</span>
                                                    )
                                                }) }
                                             </div>
                                        </div>
                                    )
                                }) : <div className="d-flex"><div className="spinner-border" role="status"></div><h2 className="ms-3">Loading</h2></div> }
                            </div>
                            <div className="tab-pane fade show" id="cancelledTab" role="tabpanel" aria-labelledby="cancelledTab">
                                {this.state.orders.cancelled ? this.state.orders.cancelled.map((order, idx) => {
                                    return (
                                        <div className="d-flex flex-column py-3 border-bottom" key={idx}>
                                             <div className="d-flex flex-column">
                                                <div>
                                                    <span className="text-muted">{order._id.slice(0, -6)}</span>
                                                    <strong className="fs-5">{order._id.slice(-6)}</strong>
                                                </div>
                                                <span>Cancelled: {new Date(order.cancelledDate).toLocaleString('en-SG', {timeZone:'Asia/Singapore'})}</span>
                                             </div>
                                             <div className="mt-2 d-flex flex-column">
                                                { order.products.map((product, idx2) => {
                                                    var currentOption = {media:{}};
                                                    product.product.options.forEach(option => {
                                                        if (option._id === product.option) {
                                                            currentOption = option;
                                                        };
                                                    });
                                                    return (
                                                        <span key={idx2}>{product.quantity}x {product.product.name} ({currentOption.name})</span>
                                                    )
                                                }) }
                                             </div>
                                        </div>
                                    )
                                }) : <div className="d-flex"><div className="spinner-border" role="status"></div><h2 className="ms-3">Loading</h2></div> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let {state} = useLocation();
    return <ManageOrders {...props} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} shop={state.shop} />
}

export default WithNavigate; 