import { faCartShopping, faLocationDot, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import constants from '../constants';

class Feed extends React.Component {
    constructor() {
        super();
        this.locationRetrievalStatuses = [
            "Locating...",
            "Location Denied",
            "Location Unavailable",
            "Location Unavailable",
            "OK"
        ];
        this.state = {
            locationRetrievalStatus: 0,
            street: "",
            results: []
        };
        this.locationErrorCb = this.locationErrorCb.bind(this);
        this.locationSuccessCb = this.locationSuccessCb.bind(this);
    };

    componentDidMount() {
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        try {
        } catch {}
        this.getCurrentLocation()
    };

    getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.locationSuccessCb, this.locationErrorCb);
        } else {
            this.setState({locationRetrievalStatus:this.locationRetrievalStatuses.NOT_SUPPORTED});
        }
    };

    locationSuccessCb(res) {
        const lat = res.coords.latitude;
        const lon = res.coords.longitude;
        const initData = "query_id=AAFbinFaAAAAAFuKcVovSKWb&user=%7B%22id%22%3A1517390427%2C%22first_name%22%3A%22SecondNH%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SecondNH%22%2C%22language_code%22%3A%22en%22%7D&auth_date=1653534720&hash=ff75fccf1de0e0b03fed30bbf8c574aa7fc30cc258dbf35dcfca1ce877dd3e69"//window.Telegram.WebApp.initData;
        fetch(`${constants.API_BASE_URL}/customer/feed/retrieve`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                initData,
                coords: [lon,lat]
            })
        }).then(async res => await res.json())
        .then(res => {
            if (res.ok) {
                this.setState({locationRetrievalStatus:4, street:res.data.street, results:res.data.results})
            } else {

            }
        });
    };

    locationErrorCb(err) {
        this.setState({locationRetrievalStatus:err.code});
    };

    showFeed() {
        if (this.state.locationRetrievalStatus === 0) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary mt-3" role="status"></div>
                </div>
            )
        } else if (this.state.locationRetrievalStatus === 4 && this.state.results.length) {
            return (
                <div className="d-flex flex-column align-items-center">
                    {this.state.results.map((shop, idx) => {
                        return (
                            <div className="d-flex flex-column mt-3" key={idx}>
                                <div className="d-flex flex-column bg-light p-3" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                                    <strong>{shop.displayName}</strong>
                                    <span className="muted">{shop.shop.address.street}{shop.shop.address.unitNo ? `, ${shop.shop.address.unitNo}` : ""}</span>
                                </div>
                                <div className="d-flex flex-nowrap mt-3">
                                    {shop.products.map((item, idx) => {
                                        const onlyOneOption = item.options.length === 1;
                                        var [lowestCostPrice, lowestListPrice] = Array(3).fill(Infinity);
                                        var [highestCostPrice, highestListPrice] = Array(3).fill(0);
                                        item.options.forEach(option => {
                                            if (option.costPrice < lowestCostPrice) {
                                                lowestCostPrice = option.costPrice;
                                            };
                                            if (option.listPrice < lowestListPrice) {
                                                lowestListPrice = option.listPrice;
                                            };
                                            if (option.costPrice > highestCostPrice) {
                                                highestCostPrice = option.costPrice;
                                            };
                                            if (option.listPrice > highestListPrice) {
                                                highestListPrice = option.listPrice;
                                            };
                                        });
                                        return (
                                            <div id={`product_${item._id}`} className="card mb-3" style={{width:"48%"}} key={idx} onClick={() => this.props.navigate(`/customer/product/${item._id}`, {state:{...item, shop}})}>
                                                <img className="card-img-top" src={`${constants.PRODUCT_MEDIA_BASE_URL}/${item.options[0].media.filename}`} />
                                                <div className="card-body">
                                                    <h5 className="card-title mt-2">{item.name}</h5>
                                                    <div className="d-flex flex-column">
                                                        <span className="text-decoration-line-through text-muted" style={{fontSize:'0.75em'}}>${lowestCostPrice.toFixed(2)}{!onlyOneOption?` - ${highestCostPrice.toFixed(2)}`:''}</span>
                                                        <span className="fs-5 fw-bold">${lowestListPrice.toFixed(2)}{!onlyOneOption?` - ${highestListPrice.toFixed(2)}`:''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        } else if (this.state.locationRetrievalStatus === 4 && !this.state.results.length) {
            return (
                <div className="d-flex flex-column align-items-center my-3">
                    <h1>No shops in your area</h1>
                </div>
            )
        } else {
        };
    };

    render() {
        return (
            <div className="vh-100">
                <h1 id="sessionExpired" className="d-none">Your session has expired.<br/><br/>Close and launch this webpage from Telegram again.</h1>
                <div className="container my-3">
                    <div className="d-flex align-items-center mb-3">
                        <h1 className="ms-3 m-0">Nearby</h1>
                        <div className="ms-auto me-3">
                            <FontAwesomeIcon icon={faSearch} className="fs-3 text-primary" onClick={() => this.props.navigate("/customer/search")} />
                            <FontAwesomeIcon icon={faCartShopping} className="fs-3 ms-4 text-primary" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center bg-primary p-2 ps-4" style={{marginLeft:"-.75rem",marginRight:"-.75rem"}}>
                        <FontAwesomeIcon icon={faLocationDot} className="fs-3 text-white" />
                        <span className="ms-3 fs-3 text-white">{this.state.locationRetrievalStatus === 4 ? this.state.street : this.locationRetrievalStatuses[this.state.locationRetrievalStatus]}</span>
                    </div>
                    {this.showFeed()}
                </div>
            </div>
        );
    };
};

function WithNavigate(props) {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    return <Feed {...props} navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} />
}

export default WithNavigate; 