import React from 'react';
import ReactDOM from 'react-dom/client';
import 'jquery/dist/jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import 'bootstrap/dist/js/bootstrap';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Balance from './Balance';
import MerchantMain from './merchant/main';
import CreateShop from './merchant/CreateShop';
import CreateShopTransient from './merchant/CreateShopTransient';
import JoinShop from './merchant/JoinShop';
import SelectShop from './merchant/SelectShop';
import ManageMenu from './merchant/ManageMenu';
import ManageOrders from './merchant/ManageOrders';
import AddProduct from './merchant/AddProduct';
import Feed from './customer/Feed';
import Search from './customer/Search';
import SearchResults from './customer/SearchResults';
import Product from './customer/Product';
import Cart from './customer/Cart';
import ShopCart from './customer/ShopCart';
import Orders from './customer/Orders';
import MerchantProduct from './merchant/Product';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/balance' element={<Balance />} />
        <Route path='/merchant' element={<MerchantMain />} />
        <Route path='/merchant/shop/create' element={<CreateShop />} />
        <Route path='/merchant/shop/join' element={<JoinShop />} />
        <Route path='/merchant/shop/select' element={<SelectShop />} />
        <Route path='/merchant/shop/:id/menu' element={<ManageMenu />} />
        <Route path='/merchant/shop/:id/orders' element={<ManageOrders />} />
        <Route path='/merchant/shop/:id/menu/product/add' element={<AddProduct />} />
        <Route path='/merchant/shop/:id/menu/product/:id' element={<MerchantProduct />} />
        <Route path='/customer/feed' element={<Feed />} />
        <Route path='/customer/search' element={<Search />} />
        <Route path='/customer/search/:query' element={<SearchResults />} />
        <Route path='/customer/product/:id' element={<Product />} />
        <Route path='/customer/cart' element={<Cart />} />
        <Route path='/customer/cart/shop/:id' element={<ShopCart />} />
        <Route path='/customer/orders' element={<Orders />} />
        {/* <Route path='/merchant/shop/createtransient/prompt' element={<CreateShopTransient screen="prompt" />} />
        <Route path='/merchant/shop/createtransient/success' element={<CreateShopTransient screen="success" />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
