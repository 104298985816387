const QuantityInput = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="p-0 input-group-prepend" onClick={props.value > 1 ? props.decrease : ()=>{}}>
                <span className="input-group-text">-</span>
            </div>
            <input type="number" className="form-control text-center" onChange={props.onChange} value={props.value} min={1} max={props.max} />
            <div className="p-0 input-group-append" onClick={props.increase}>
                <span className="input-group-text">+</span>
            </div>
        </div>
    )
};

export default QuantityInput;